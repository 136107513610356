import { setOptions, bootstrap } from 'vue-gtag'

export default {
  methods: {
    startGtag(gTagCode) {
      if (gTagCode) {
        setOptions({
          config: { id: gTagCode }
        })
      }
    },

    gTagOptIn() {
      bootstrap().then((gtag) => {
        // all done!
        if (gtag && this.$gtag) {
          this.$gtag.optIn();
        }
      })
    },

    gTagPageView(path) {
      if (path && this.$gtag) {
        this.$gtag.pageview(path)
      }
    },

    gTagTrackEvent(category, action, label, value) {
      if (category !== '' && action !== '' && label !== '' && this.$gtag) {
        this.$gtag.event(action, {
          'event_category': category,
          'event_label': label,
          'value': value ? value : ''
        },
        )
      }
    }
  }
}
