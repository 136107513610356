import { postObject } from '@/utils/api/api'

export default {
  methods: {
    /**
     * Remove from basket
     */
    removeFromBasket (item) {
      postObject('basket/removeFromBasket', { id: item.id }).then(response => {
        if (response.data) {
          this.$store.commit('setBasket', response.data)

          this.$store.commit('setBasketSnackbar', 'remove')
        }
      })
    },
    /**
     * Update quantity + 1
     *
     * @param {Int} item
     */
    addItem (item) {
      postObject('basket/addBasketItem', { id: item.id }).then(response => {
        if (response.data) {
          this.$store.commit('setBasket', response.data)

          this.$store.commit('setBasketSnackbar', 'add')
        }
      })
    },
    /**
     * Update quantity - 1
     *
     * @param {Int} item
     */
    removeItem (item) {
      if (item.quantity > 1) {
        postObject('basket/removeBasketItem', { id: item.id }).then(response => {
          if (response.data) {
            this.$store.commit('setBasket', response.data)

            this.$store.commit('setBasketSnackbar', 'remove')
          }
        })
      }
    }
  }
}
