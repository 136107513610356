import Vue from 'vue'
import Vuex from 'vuex'
import { findIndex } from 'lodash'
import general from '../general/general'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageTitle: '',
    pageLoaded: false,
    //
    menu: [],
    footerMenu: [],
    featuredMenu: [],
    menuShow: false,
    siteSettings: false,
    analyticsCode: false,
    siteLinks: false,
    user: false,
    userBilling: false,
    userShipping: false,
    userTitles: false,
    basket: [],
    breadcrumbs: false,
    contentLoaded: false,
    //
    postCountries: [],
    order: false,
    orderSteps: [],
    reorderState: false,
    //
    paymentOverlayState: {
      overlay: false,
      message: false
    },
    // Meta Store
    pageMeta: {
      title: 'Aroma Tea & Coffee Merchants',
      description: '',
      image: ''
    },
    defaultMetaDescription: '',
    // PayPal
    paypalLoaded: false,
    // Cart Error
    cartErrorState: false,
    cartErrorExtraMessage: false,
    // Cookie Consent
    jigsawCookies: {
      jigsaw_cookies: true,
      jigsaw_essential_cookie: 1,
      jigsaw_analytics_cookie: 1
    },
    //
    basketSnackbar: '',
    // trustpilot stats
    tpStats: false,
    // Trsanlations
    translation: false,
    // Builder Items
    viewedProduct: false,
    viewedBlogCategory: false,
    viewedBlogArticle: false,
    relatedProducts: []
  },
  mutations: {
    setSiteMenu(state, menu) {
      state.menu = menu
    },
    setFooterMenu(state, footerMenu) {
      state.footerMenu = footerMenu
    },
    setFeaturedMenu(state, featuredMenu) {
      state.featuredMenu = featuredMenu
    },
    setShowMenu(state, menuShow) {
      state.menuShow = menuShow
    },
    //
    setPageTitle(state, pageTitle) {
      state.pageTitle = pageTitle
    },
    setPageLoaded(state, pageLoaded) {
      state.pageLoaded = pageLoaded
    },
    //
    setSiteSettings(state, siteSettings) {
      if (siteSettings && siteSettings.mission_statement !== '') {
        this.commit('setDefaultMetaDescription', siteSettings.mission_statement)
      }
      state.siteSettings = siteSettings
    },
    setAnalyticsCode(state, analyticsCode) {
      state.analyticsCode = analyticsCode
    },
    setDefaultMetaDescription(state, defaultMetaDescription) {
      state.defaultMetaDescription = defaultMetaDescription
    },
    setSiteLinks(state, siteLinks) {
      state.siteLinks = siteLinks
    },
    setUser(state, user) {
      const siteUser = user
      siteUser.billing = false
      siteUser.delivery = false

      if (user && user.addresses) {
        const bIndex = findIndex(user.addresses, { billing: '1' })
        const sIndex = findIndex(user.addresses, { shipping: '1' })
        let billing = false
        let delivery = false

        if (bIndex !== -1) {
          billing = {
            id: user.addresses[bIndex].id,
            address_line_1: user.addresses[bIndex].address_line_1,
            address_line_2: user.addresses[bIndex].address_line_2,
            city: user.addresses[bIndex].city,
            county: user.addresses[bIndex].county,
            postal_code: user.addresses[bIndex].postal_code,
            country: user.addresses[bIndex].country,
            country_code: user.addresses[bIndex].country_code
          }
        }

        if (sIndex !== -1) {
          delivery = {
            id: user.addresses[sIndex].id,
            address_line_1: user.addresses[sIndex].address_line_1,
            address_line_2: user.addresses[sIndex].address_line_2,
            city: user.addresses[sIndex].city,
            county: user.addresses[sIndex].county,
            postal_code: user.addresses[sIndex].postal_code,
            country: user.addresses[sIndex].country,
            country_code: user.addresses[sIndex].country_code
          }
        }

        this.commit('setUserBilling', billing)
        this.commit('setUserShipping', delivery)

        siteUser.billing = bIndex !== -1 ? billing : false
        siteUser.delivery = sIndex !== -1 ? delivery : false
      }

      state.user = siteUser
    },
    setUserBilling(state, address) {
      state.userBilling = address
    },
    setUserShipping(state, address) {
      state.userShipping = address
    },
    setUserTitles(state, userTitles) {
      state.userTitles = userTitles
    },
    setBasket(state, basket) {
      const b = {
        discount: basket.discount,
        list: basket.list
      }

      const o = basket.order
      state.basket = b
      this.commit('setOrder', general.methods.processOrder(o))
    },
    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs
    },
    setContentLoaded(state, contentLoaded) {
      state.contentLoaded = contentLoaded
    },
    setPostCountries(state, postCountries) {
      state.postCountries = postCountries
    },
    setOrder(state, order) {
      state.order = order
    },
    setOrderSteps(state, steps) {
      state.orderSteps = steps
    },
    setReorderState(state, reorderState) {
      state.reorderState = reorderState
    },
    setPaymentOverlay(state, paymentOverlayState) {
      state.paymentOverlayState = {
        overlay: paymentOverlayState,
        message: paymentOverlayState
      }
    },
    // Store Meta
    setPageMeta(state, meta) {
      state.pageMeta = {
        title: meta.title,
        description: meta.description,
        image: meta.image
      }
    },
    setPaypalLoaded(state, loaded) {
      state.paypalLoaded = loaded
    },
    //
    setCartErrorState(state, cartErrorState) {
      state.cartErrorState = cartErrorState
    },
    setCartErrorExtraMessage(state, cartErrorExtraMessage) {
      state.cartErrorState = cartErrorExtraMessage
    },
    setJigsawCookies(state, jigsawCookies) {
      state.jigsawCookies = jigsawCookies
    },
    //
    setBasketSnackbar(state, basketSnackbar) {
      state.basketSnackbar = basketSnackbar
    },
    //
    setTPStats(state, tpStats) {
      state.tpStats = tpStats
    },
    //
    setTranslation(state, translation) {
      state.translation = translation
    },
    // Builder
    setRelatedProducts(state, relatedProducts) {
      state.relatedProducts = relatedProducts
    },
    setViewedProduct(state, viewedProduct) {
      state.viewedProduct = viewedProduct
    },
    setViewedBlogCategory(state, viewedBlogCategory) {
      state.viewedBlogCategory = viewedBlogCategory
    },
    setViewedBlogArticle(state, viewedBlogArticle) {
      state.viewedBlogArticle = viewedBlogArticle
    }
  },
  getters: {
    getUser: state => state.user,
    //
    getPageTitle: state => state.pageTitle,
    getPageLoaded: state => state.pageLoaded,
    //
    getUserBilling: state => state.userBilling,
    getUserShipping: state => state.userShipping,
    getOrder: state => state.order,
    getOrderSteps: state => state.orderSteps,
    getReorderState: state => state.reorderState,
    getPaymentOverlayState: state => state.paymentOverlayState,
    //
    getUserTitles: state => state.userTitles,
    getSiteMenu: state => state.menu,
    getFooterMenu: state => state.footerMenu,
    getFeaturedMenu: state => state.featuredMenu,
    getMenuShow: state => state.menuShow,
    getSiteSettings: state => state.siteSettings,
    getAnalyticsCode: state => state.analyticsCode,
    getSiteLinks: state => state.siteLinks,
    getBasket: state => state.basket,
    getContentLoaded: state => state.contentLoaded,
    getPostCountries: state => state.postCountries,
    getPageMeta: state => state.pageMeta,
    getDefaultMetaDescription: state => state.defaultMetaDescription,
    getPaypalLoaded: state => state.paypalLoaded,
    //
    getCartErrorState: state => state.cartErrorState,
    getCartErrorExtraMessage: state => state.cartErrorExtraMessage,
    getJigsawCookies: state => state.jigsawCookies,
    //
    getBasketSnackbar: state => state.basketSnackbar,
    //
    gettpStats: state => state.tpStats,
    //
    getTranslation: state => state.translation,
    // Builder
    getRelatedProducts: state => state.relatedProducts,
    getViewedProduct: state => state.viewedProduct,
    // Builder Blog
    getViewedBlogCategory: state => state.viewedBlogCategory,
    getViewedBlogArticle: state => state.viewedBlogArticle
  }
})
