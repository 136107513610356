import axios from 'axios'

export const API_URL = '/api/v1/front/'

const api = axios.create({
  baseURL: API_URL,
  timeout: 90000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const live = 'https://www.ipg-online.com/connect/gateway/'
// const staging = 'https://staging.jigsaw-web.co.uk/api/v1/'
const dev = 'https://test.ipg-online.com/connect/gateway/'

const remote = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? dev : live,
  timeout: 90000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})



export {
  api,
  remote
}
