import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#336699',
        secondary: '#8b567c',
        accent: '#82B1FF',
        error: '#f84971',
        info: '#2196F3',
        success: '#30be76',
        warning: '#f8b449',
        black: '#000000',
        //
        theme1: '#000000',
        theme2: '#767676',
        theme3: '#cccccc',
        theme4: '#e6e6e6',
        theme5: '#f7f8fa',
        theme6: '#ffffff'
      }
    }
  },
  icons: {
    iconfont: 'fa' // 'mdiSvg' || 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  }
});
