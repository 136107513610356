<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewbox"
    role="presentation"
    :style="{fill: svgColor}"
  >
    <IconBase :icon="iconName" />
  </svg>
</template>

<script>
import IconBase from '@/components/icons/iconBase'

export default {
  name: 'ApolloIcons',
  components: {
    IconBase
  },
  props: {
    /**
     * iconName is the name of the icon.
     *
     * @param {String}
     */
    iconName: {
      type: String,
      default: 'menu'
    },
    /**
     * width is the svg width setting.
     *
     * @param {Number, String}
     */
    width: {
      type: [Number, String],
      default: 32
    },
    /**
     * height is the svg height setting.
     *
     * @param {Number, String}
     */
    height: {
      type: [Number, String],
      default: 32
    },
    /**
     * viewbox is the svg viewbox.
     *
     * @param {String}
     */
    color: {
      type: String,
      default: 'black'
    }
  },
  computed: {
    viewbox () {
      const vb = {
        'account-details': '0 0 32 32',
        basket: '0 0 32 32',
        'basket-1': '0 0 32 32',
        'basket-2': '0 0 32 32',
        'basket-3': '0 0 32 32',
        'basket-4': '0 0 32 32',
        bean: '0 0 32 32',
        book: '0 0 32 32',
        delivery: '0 0 32 32',
        redelivery: '0 0 32 32',
        subscription: '0 0 32 32',
        file: '0 0 32 32',
        gift: '0 0 32 32',
        'header-user': '0 0 456 456',
        'header-user-filled': '0 0 32 32',
        logo: '0 0 288.1 126.3',
        logout: '0 0 32 32',
        menu: '0 0 32 32',
        pantry: '0 0 32 32',
        'pantry-add': '0 0 32 32',
        'pantry-remove': '0 0 32 32',
        'price-tag': '0 0 32 32',
        jigsaw: '0 0 60 60',
        'jigsaw-logo': '0 0 160 48',
        'jigsaw-solid': '0 0 60 60',
        trustpilot: '0 0 126 31',
        tstar1: '0 0 512 96',
        'tstar1.5': '0 0 512 96',
        tstar2: '0 0 512 96',
        'tstar2.5': '0 0 512 96',
        tstar3: '0 0 512 96',
        'tstar3.5': '0 0 512 96',
        tstar4: '0 0 512 96',
        'tstar4.5': '0 0 512 96',
        tstar5: '0 0 512 96',
        'card--paypal': '0 0 780 501',
        'card--visa': '0 0 750 471',
        'card--mastercard': '0 0 482.51 374',
        'card--maestro': '0 0 482.6 374.31',
        'twitter-x': '0 0 512 512'
      }
      return vb[this.iconName]
    },
    colors () {
      return this.$vuetify.theme.themes.light
    }
  },
  created () {
    this.svgColor = this.colors[this.color]
  }
}
</script>
