<template>
  <header
    v-if="translation && coreSettings"
    :class="[
      'main-header',
      floatingHeader ? 'is-floating' : '',
      coreSettings.pre_header_notice === '1' && translation.all_pages.header.pre_header_notice !== '' ? 'has-pre-utility-bar' : '',
      coreSettings.post_header_notice === '1' && translation.all_pages.header.post_header_notice !== '' ? 'has-post-utility-bar' : ''
    ]"
  >
    <div class="d-flex flex-column height-100">
      <div
        v-if="coreSettings.pre_header_notice === '1'"
        class="utility-bar apollo-card-text"
        :style="{
          'background-color': coreSettings.pre_header_notice_colour,
          color: colourContrast(coreSettings.pre_header_notice_colour)
        }"
      >
        <div v-text="translation.all_pages.header.pre_header_notice" />
      </div>
      <div class="main-header__bar">
        <div class="layout-container height-100">
          <div class="d-flex align-center height-100">
            <div class="pa-1 pl-sm-0 pa-sm-2">
              <v-btn
                class="d-sm-flex d-none"
                icon
                large
                @click="drawerAction()"
              >
                <icons icon-name="menu" />
              </v-btn>

              <v-btn
                class="d-flex d-sm-none"
                icon
                @click="drawerAction()"
              >
                <icons icon-name="menu" />
              </v-btn>
            </div>

            <div class="d-flex d-sm-none pa-0">
              <v-btn
                icon
                @click="showMobileSearch()"
              >
                <v-icon
                  large
                  v-text="'mdi-magnify'"
                />
              </v-btn>
            </div>

            <div class="pa-0 px-sm-2 flex-sm-grow-0 flex-grow-1">
              <router-link
                class="d-flex align-center justify-center"
                :to="{path: '/'}"
              >
                <icons
                  class="main-header__logo"
                  icon-name="logo"
                />
              </router-link>
            </div>

            <div class="d-none d-sm-flex flex-grow-1 px-6">
              <div class="header-search">
                <v-text-field
                  ref="searchSite"
                  v-model="searchSite"
                  class="pb-3 pb-sm-0"
                  :placeholder="translation.all_pages.header.search_placeholder_text"
                  autocomplete="do-not-autofill"
                  :append-icon="searchSite && searchSite.length > 2 ? 'mdi-magnify' : ''"
                  type="text"
                  name="aromaSearch"
                  clear-icon="mdi-close"
                  clearable
                  hide-details
                  @input="debounceSearchInput"
                  @blur="showResultsPanel('blur')"
                  @focus="showResultsPanel('focus')"
                  @click:clear.stop="clearMessage()"
                  @click:append="search()"
                  @keypress="checkSearch"
                />

                  <div
                  :class="[
                    'header-search-list',
                    showResults ? 'is-visable' : ''
                  ]"
                >
                  <div v-if="searchResults.length > 0">
                    <v-card
                      v-for="(item, key) in searchResults"
                      :key="'result_'+key"
                      class="search_item"
                      flat
                      link
                      @click="clickSearchResults(item.category_url + item.url)"
                    >
                      <v-row class="ma-0">
                        <v-col cols="2">
                          <v-img v-if="item.type === 'product'" :src="'/images/products/small/' + item.image" :aspect-ratio="16/9" />
                          <v-img v-if="item.type === 'blog'" :src="'/images/blog/articles/' + item.image" :aspect-ratio="16/9" />
                        </v-col>
                        <v-col cols="10" class="d-flex flex-column jigsaw-body-text">
                          <div v-text="item.title" />
                          <div v-text="item.snippet" />
                          <div v-if="item.type === 'product'" class="d-flex">
                            <span
                              v-if="item.price_range.length > 1"
                              v-html="translation.general.labels.from + '&nbsp;'"
                            />
                            <div v-text="'£' + parseMoney(item.price_range[0])" />
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                  <div v-else>
                    <div
                      class="py-3"
                      v-text="translation.all_pages.header.search_no_results_text"
                    />
                  </div>
                  <!-- {{searchValue}} -->
                </div>
              </div>
            </div>

            <div class="pa-2">
              <Basket />
            </div>
            <div class="pa-2 pr-sm-0">
              <Account />
            </div>
          </div>
        </div>
      </div>
      <div v-if="featuredMenu.length > 0" class="featured-header__bar">
        <div class="featured-navigation">
          <div
            v-for="(item, key) in featuredMenu"
            :key="key"
            class="featured-navigation__item px-3"
          >
            <router-link
              :to="{path: item.url}"
              class="featured-navigation__link"
            >
              {{item.title}}
            </router-link>
          </div>
        </div>
      </div>
      <div
        v-if="coreSettings.post_header_notice === '1'"
        class="utility-bar apollo-card-text"
        :style="{
          'background-color': hex2rgba(coreSettings.post_header_notice_colour, .95),
          color: colourContrast(coreSettings.post_header_notice_colour)
        }"
      >
        <div v-text="translation.all_pages.header.post_header_notice" />
      </div>
    </div>


    <v-dialog
      v-model="mobileSearchDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card
        v-touch="{
          down: () => hideMobileSearch()
        }"
        class="d-flex flex-column"
      >
        <v-btn
          fab
          small
          color="secondary"
          class="mobile-search__close"
          @click="hideMobileSearch()"
        >
          <v-icon v-text="'mdi-close'" />
        </v-btn>
        <div class="d-flex justify-center mt-12 py-12">
          <icons
            class="mobile-search__logo"
            icon-name="logo"
          />
        </div>
        <v-card-text class="px-0">
          <v-row no-gutters>
            <v-col
              cols="12"
              class="jigsaw-card-text d-flex align-center pa-3 pb-12"
              v-text="translation.all_pages.header.mobile_search_placeholder_text"
            />
            <v-col
              cols="12"
              class="d-flex align-center pa-0 px-3"
            >
              <v-text-field
                ref="mobileSearchSite"
                v-model="searchSite"
                class="pb-3 pb-sm-0"
                :placeholder="translation.all_pages.header.search_placeholder_text"
                autocomplete="none"
                :append-icon="searchSite && searchSite.length > 2 ? 'mdi-magnify' : ''"
                type="text"
                clear-icon="mdi-close"
                clearable
                hide-details
                @click:clear.stop="clearMessage()"
                @click:append="search()"
                @keypress="checkSearch"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <div class="mx-auto mt-auto pa-6">
          <span v-html="translation.all_pages.header.mobile_close_hint" />
          <v-icon
            large
            v-text="'mdi-gesture-swipe-down'"
          />
        </div>
      </v-card>
    </v-dialog>
  </header>
</template>

<script>
import { postObject } from '@/utils/api/api'
import icons from '@/components/icons/icons'
import general from '@/utils/general/general'
import Basket from './shoppingBasket/basket'
import Account from './account'
import { debounce } from 'lodash'
export default {
  name: 'HeaderPartial',
  components: {
    icons,
    Basket,
    Account
  },
  mixins: [general],
  //
  data: () => ({
    drawer: false,
    //
    settings: {},
    //
    featuredMenu: [],
    hasFeatureMenu: false,
    //
    basketShow: false,
    basket: [],
    //
    searchSite: '',
    floatingHeader: false,
    //
    mobileSearchDialog: false,
    //
    searchLodaing: false,
    searchResults: [],
    showResults: false
  }),
  //
  computed: {
    translation () {
      return this.$store.getters.getTranslation
    },
    coreSettings () {
      return this.$store.getters.getSiteSettings
    }
  },
  //
  watch: {
    $route (to, from) {
      if (from.path !== to.path) {
        setTimeout(() => {
          this.clearMessage()
        }, 500)
      }
    }
  },
  //
  created () {
    // Menu State
    this.$store.watch(
      (state, getters) => getters.getMenuShow, (result) => {
        this.drawer = result
      }, {
        immediate: true
      }
    )

    // Settings
    this.$store.watch(
      (state, getters) => getters.getSiteLinks, (result) => {
        this.settings = result
      }, {
        immediate: true
      }
    )

    // Featured Menu
    this.$store.watch(
      (state, getters) => getters.getFeaturedMenu, (result) => {
        if (result && result.length > 0) {
          this.featuredMenu = result
          document.body.classList.add('has-feature-bar')
        } else {
          this.featuredMenu = []
          document.body.classList.remove('has-feature-bar')
        }
      }, {
        immediate: true
      }
    )

    window.addEventListener('scroll', this.handleScroll)
  },
  mounted () {
    let count = 0
    if (this.coreSettings.pre_header_notice === '1' && this.translation.all_pages.header.pre_header_notice !== '') {
      count++
    }

    if (this.coreSettings.post_header_notice === '1' && this.translation.all_pages.header.post_header_notice !== '') {
      count++
    }

    if (count > 0) {
      if (count === 1) {
        document.body.classList.add('has-utility-bar')
      }

      if (count === 2) {
        document.body.classList.add('has-utility-bars')
      }
    }
  },
  //
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  //
  methods: {
    drawerAction () {
      this.drawer = !this.drawer
      this.$store.commit('setShowMenu', this.drawer)
    },
    //
    handleScroll () {
      this.floatingHeader = window.scrollY > 1
    },
    debounceSearchInput: debounce(function (e) {
      if (e && e.length > 2) {
        this.searchLodaing = true

        postObject('core/getSearchResultsAutocomplete', { query: e }).then(response => {
          if (response.data) {
            this.searchResults = response.data
            this.showResults = true
          }
          this.searchLodaing = false
        })
      } else {
        this.showResults = false
        this.searchLodaing = false
        this.searchResults = []
      }
    }, 500),
    //
    showResultsPanel (action) {
      const state = this.showResults

      if (!state && action !== 'blur' && this.searchResults.length > 0) {
        this.showResults = true
      } else if (action === 'blur' && this.searchResults.length < 1) {
        this.showResults = false
      }
    },
    clickSearchResults (path) {
      if (path !== this.$route.path) {
        this.clearMessage()
        this.$router.push({ path: path })
      }
    },
    checkSearch (e) {
      if (e.charCode === 13 && this.searchSite !== '') {
        this.search()
      }
    },
    search () {
      if (this.settings.search_url !== '') {
        const path = this.settings.search_url + '/' + encodeURI(this.searchSite)
        this.mobileSearchDialog = false

        if (path !== this.$route.path) {
          this.showResultsPanel('blur')
          this.clearMessage()
          this.$router.push({ path: path })

          setTimeout(() => {
            this.$refs.searchSite.blur()
            this.showResultsPanel('blur')
          }, 505)
        }
      }
    },
    clearMessage () {
      this.searchSite = ''
      this.showResults = false
      this.searchLodaing = false
      this.searchResults = []
    },
    showMobileSearch () {
      this.mobileSearchDialog = true
    },
    hideMobileSearch () {
      this.mobileSearchDialog = false
      setTimeout(() => {
        this.clearMessage()
      }, 200)
    }
  }
}
</script>
