<template>
  <v-app
    v-if="loaded && translation && !maintenance"
    dark
    class="page-wrapper"
    :class="[
      isRoot ? 'is-root' : ''
    ]"
  >
    <Snowf
      v-if="snow && showSnow"
      class="aroma-snow"
      :amount="snowCount"
      :size="snowSize"
      :speed="snowSpeed"
      :wind="snowWind"
      :opacity="0.9"
      :swing="snowSwing"
      :image="null"
      :zIndex="6"
      :resize="true"
      color="#ccc"
    />
    <div
      v-if="paymentOverlay"
      :class="[
        'payment-overlay',
        showPaymentOverlay ? 'is-active' : ''
      ]"
    >
      <v-progress-circular
        v-if="paymentOverlayMessage"
        indeterminate
        size="64"
      />
      <div
        v-if="paymentOverlayMessage"
        class="jigsaw-text-h4 mt-3"
        v-text="'Processing your order...'"
      />
    </div>
    <Header />
    <Menu />
    <Breadcrumb />
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view :key="$route.path" />
    </transition>
    <Footer />
  </v-app>
  <Loader v-else />
</template>

<script>
import Header from '@/partials/header'
import Footer from '@/partials/footer'
import Breadcrumb from '@/components/breadcrumb/breadcrumb'
import Loader from '@/components/loader/loader'
import Menu from '@/partials/menu'
import { getData } from './utils/api/api'
import gtm from './utils/analytics/gtag'
import jigsawPay from '@/utils/paypal/paypal'
import Snowf from 'vue-snowf'
export default {
  name: 'AromaApolloApp',

  components: {
    Header,
    Footer,
    Breadcrumb,
    Loader,
    Menu,
    Snowf
  },
  //
  mixins: [jigsawPay, gtm],
  //
  metaInfo: {
    // Children can override the title.
    title: 'Aroma Tea & Coffee Merchants',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    // titleTemplate: '%s ← My Site',
    // Define meta tags here.
    meta: [
      {
        'http-equiv': 'Content-Type',
        content: 'text/html; charset=utf-8'
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1'
      },
      {
        vmid: 'description',
        name: 'description',
        content: 'I have things here on my site.'
      }
    ],
    links: [
      {
        rel: 'canonical',
        href: 'https://www.aroma-coffee.co.uk'
      }
    ]
  },
  data: () => ({
    translation: false,
    isRoot: true,
    loaded: true,
    fadeout: false,
    footerMenu: [],
    progress: 0,
    maintenance: false,
    //
    clientID: false,
    //
    paymentOverlay: true,
    showPaymentOverlay: true,
    paymentOverlayMessage: false,
    //
    showSnow: false,
    //
    snowSize: 0,
    snowSpeed: 0,
    snowCount: 0,
    snowWind: 0,
    snowSwing: 0,
    snow: false,
    //
    toastTimeout: 2500,
    analyticsCode: false,
    gtagCode: false
  }),
  //
  watch: {
    $route (to, from) {
      if (from.path !== to.path) {
        this.checkRoute()
      }

      if (this.analyticsCode) {
        this.$gtag.pageview(to.path)
      }
    }
  },
  //
  created () {
    this.checkRoute()

    this.$store.watch(
      (state, getters) => getters.getSiteSettings, (result) => {
        if (result) {
          this.showSnow = result.snow === '1'
          this.snowSize = parseInt(result.snowSize)
          this.snowSpeed = parseFloat(result.snowSpeed)
          this.snowCount = parseInt(result.snowCount)
          this.snowWind = parseInt(result.snowWind)
          this.snowSwing = parseInt(result.snowSwing)

          this.checkForSnow()
        }
      }, {
        immediate: true
      }
    )

    getData('core/getSiteNavigation').then(response => {
      if (response.data) {
        this.$store.commit('setSiteMenu', response.data.main)
        this.$store.commit('setFooterMenu', response.data.footer)
        this.$store.commit('setFeaturedMenu', response.data.featured)
      }
    })

    getData('core/getSiteSettings').then(response => {
      if (response.data) {
        this.translation = response.data.translation
        this.$store.commit('setSiteSettings', response.data.core)
        this.$store.commit('setUserTitles', response.data.titles)
        this.$store.commit('setTPStats', response.data.trustpilot)
        this.$store.commit('setTranslation', response.data.translation)
        this.analyticsCode = response.data.core.gtag_code

        // this.loadPayPalScript(response.data.core)

        this.$store.commit('setAnalyticsCode', response.data.core.gtag_code)
        // this.initAnalyticsCheck(response.data.core.gtag_code)
      }
    })

    getData('core/getSiteLinks').then(response => {
      if (response.data) {
        this.$store.commit('setSiteLinks', response.data)
      }
    })

    getData('basket/getBasket').then(response => {
      if (response.data) {
        this.$store.commit('setBasket', response.data)
      }
    })

    getData('users/getUser').then(response => {
      if (response.data) {
        this.$store.commit('setUser', response.data)
      }
    })

    getData('core/getPostCountries').then(response => {
      if (response.data) {
        this.$store.commit('setPostCountries', response.data)
      }
    })

    this.$store.watch(
      (state, getters) => getters.getPaymentOverlayState, (result) => {
        if (result) {
          if (result.overlay) {
            // show
            this.paymentOverlay = result.overlay
            this.paymentOverlayMessage = result.message
            this.showPaymentOverlay = true
          } else {
            // hide
            this.showPaymentOverlay = false
            setTimeout(() => {
              this.paymentOverlayMessage = result.message
              this.paymentOverlay = result.overlay
            }, 200)
          }
        }
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getContentLoaded, (result) => {
        if (result) {
          this.checkForSnow()
        }
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getBasketSnackbar, (result) => {
        this.showToast(result)
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getAnalyticsCode, (result) => {
        this.analyticCode = result

        this.startGtag(this.analyticCode)
      }, {
        immediate: true
      }
    )
  },
  //
  mounted () {
    this.clickWatcher()
  },
  methods: {
    showToast(state) {
      if (state === 'add') {
        this.$toast(this.translation.all_pages.notifications.add_to_basket, {
          position: "bottom-center",
          timeout: this.toastTimeout,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: true,
          hideProgressBar: false,
          closeButton: false,
          icon: 'mdi mdi-36px mdi-check-circle-outline',
          rtl: false
        })
      }

      if (state === 'remove') {
        this.$toast.warning(this.translation.all_pages.notifications.remove_from_basket, {
          position: "bottom-center",
          timeout: this.toastTimeout,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: false,
          icon: 'mdi mdi-36px mdi-alert-circle-outline',
          rtl: false
        })
      }

      if (state === 'reorder') {
        this.$toast.success(this.translation.all_pages.notifications.add_order_to_basket, {
          position: "bottom-center",
          timeout: this.toastTimeout,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: true,
          hideProgressBar: false,
          closeButton: false,
          icon: 'mdi mdi-36px mdi-check-circle-outline',
          rtl: false
        })
      }

      if (state === 'favorder') {
        this.$toast.success(this.translation.all_pages.notifications.add_favourite_to_basket, {
          position: "bottom-center",
          timeout: this.toastTimeout,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: true,
          hideProgressBar: false,
          closeButton: false,
          icon: 'mdi mdi-36px mdi-check-circle-outline',
          rtl: false
        })
      }

      this.$store.commit('setBasketSnackbar', false)
    },
    checkRoute () {
      this.snow = false
      this.isRoot = this.$route.path === '/'
      this.$store.commit('setContentLoaded', false)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    //
    checkForSnow () {
      this.snow = false
      const now = new Date()

      if (now.getMonth() === 11 && this.showSnow) {
        setTimeout(() => {
          this.snow = true
        }, 250)
      }
    },
    //
    clickWatcher () {
      const _self = this
      document.addEventListener('click', function (event) {
        if (event.target.classList[0] === 'apollo-internal-link') {
          event.preventDefault()
          if (event.stopPropagation) {
            event.stopPropagation()
          } else if (window.event) {
            window.event.cancelBubble = true
          }

          if (_self.$route.path !== event.target.pathname) {
            _self.$router.push({ path: event.target.pathname })
          }
        }
      })
    },
    //
    initAnalyticsCheck (code) {
      if (code && !this.analyticsCode) {
        this.analyticsCode = code
        if (this.$cookies.get('apollo_ac') && this.$cookies.get('apollo_ac') === '1') {
          this.startGtag(code)
        } else {
          this.gTagPageView(this.$route.path)
        }
      }
    }
  }
}
</script>
<style lang="scss">
@import '../scss/styles.scss';
</style>
