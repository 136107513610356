<template>
  <v-footer
    v-if="year && translation"
    color="theme4"
    class="main-footer pa-0 mt-6"
  >
    <div class="layout-container">
      <v-row class="mx-0 py-3">
        <v-col
          cols="12"
          class="pb-1"
          order="1"
        >
          <icons
            class="footer__logo"
            icon-name="logo"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          order="2"
        >
          <div
            class="jigsaw-text-h4 font-weight-medium jigsaw-secondary"
            v-text="translation.all_pages.footer.misson_statement_title"
          />
          <div
            class="d-flex jigsaw-body-text"
            v-text="settings.mission_statement"
          />

          <div
            class="jigsaw-text-h4 font-weight-medium jigsaw-secondary mt-3"
            v-text="translation.all_pages.footer.contact_title"
          />
          <div class="d-flex align-center">
            <v-icon
              v-text="'mdi-phone-check'"
            />
            <span
              class="jigsaw-body-text py-1"
              v-text="settings.phone"
            />
          </div>
          <div class="d-flex align-center">
            <v-icon
              v-text="'mdi-email-open-outline'"
            />
            <span
              class="jigsaw-body-text py-1"
              v-text="settings.email"
            />
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="6"
          order="4"
          order-md="3"
        >
          <div
            class="jigsaw-text-h4 font-weight-medium jigsaw-secondary"
            v-text="translation.all_pages.footer.footer_links_title"
          />
          <v-row>
            <v-col
              v-for="(item, nKey) in navigation"
              :key="nKey"
              cols="6"
              class="pb-0 jigsaw-body-text"
            >
              <router-link
                class="footer-link"
                :to="{ path: item.url }"
                exact-active-class=""
                active-class=""
                v-text="item.title"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="settings.mailchimp_subscribe !== '0'"
          cols="12"
          sm="6"
          md="3"
          order="3"
          order-md="4"
        >
          <div v-if="settings.mailchimp_list !== ''">
            <v-btn
              v-if="!userSubscribed"
              color="secondary"
              block
              large
              @click="openDialog()"
            >
              <v-icon
                left
                v-text="'mdi-email'"
              />
              <span
                class="jigsaw-button-text pl-2"
                v-text="translation.all_pages.footer.subscribe_title"
              />
            </v-btn>
            <v-dialog
              v-if="!userSubscribed"
              v-model="subscribeDialog"
              persistent
              scrollable
              max-width="600"
            >
              <v-card>
                <v-card-title class="align-center pa-2 pl-5 theme5">
                  <span
                    v-text="translation.all_pages.subscribe_popup.popup_title"
                  />
                  <v-spacer />
                  <v-btn
                    class="basket-close"
                    icon
                    @click="closeDialog()"
                  >
                    <v-icon v-text="'mdi-close'" />
                  </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="pb-0 pt-2">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pb-0"
                    >
                      <v-text-field
                        v-model="userSubscribeForm.fname"
                        prepend-inner-icon="mdi-email"
                        class="mb-n1"
                        :label="translation.all_pages.subscribe_popup.first_name_field + ' *'"
                        :disabled="userSubscribeState"
                        outlined
                        dense
                        :success="$v.userSubscribeForm.fname.$dirty"
                        :error-messages="userSubscribeFnameErrors"
                        @blur="$v.userSubscribeForm.fname.$touch()"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pb-0"
                    >
                      <v-text-field
                        v-model="userSubscribeForm.lname"
                        prepend-inner-icon="mdi-email"
                        class="mb-n1"
                        :label="translation.all_pages.subscribe_popup.last_name_field + ' *'"
                        :disabled="userSubscribeState"
                        outlined
                        dense
                        :success="$v.userSubscribeForm.lname.$dirty"
                        :error-messages="userSubscribeLnameErrors"
                        @blur="$v.userSubscribeForm.lname.$touch()"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="pb-0"
                    >
                      <v-text-field
                        v-model="userSubscribeForm.email"
                        prepend-inner-icon="mdi-email"
                        class="mb-n1"
                        :label="translation.all_pages.subscribe_popup.email_field + ' *'"
                        :disabled="userSubscribeState"
                        outlined
                        dense
                        :success="$v.userSubscribeForm.email.$dirty"
                        :error-messages="userSubscribeEmailErrors"
                        @blur="$v.userSubscribeForm.email.$touch()"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="jigsaw-card-text py-0"
                      v-text="translation.all_pages.subscribe_popup.popup_question"
                    />
                    <v-col
                      cols="6"
                      class="py-0"
                    >
                      <v-checkbox
                        v-model="userSubscribeForm.coffee"
                        :label="translation.all_pages.subscribe_popup.coffee_label"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      class="py-0"
                    >
                      <v-checkbox
                        v-model="userSubscribeForm.tea"
                        :label="translation.all_pages.subscribe_popup.tea_label"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions class="pa-4">
                  <v-spacer />
                  <v-btn
                    large
                    color="theme4"
                    class="px-3"
                    :disabled="userSubscribeState"
                    @click="closeDialog()"
                  >
                    <span
                      class="jigsaw-button-text"
                      v-text="translation.general.buttons.close"
                    />
                  </v-btn>
                  <v-btn
                    large
                    color="secondary"
                    class="px-3"
                    :loading="userSubscribeState"
                    @click="subscrbeAction()"
                  >
                    <span
                      class="jigsaw-button-text"
                      v-text="translation.all_pages.subscribe_popup.subscribe_button"
                    />
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <div v-else>
              <div
                class="jigsaw-text-script jigsaw-text-h1 font-weight-medium text-center"
                v-html="$sanitize(translation.all_pages.subscribe_popup.thank_you_message)"
              />
            </div>
          </div>
        </v-col>

        <v-col
          cols="12"
          class="py-sm-0"
          order="5"
        >
          <v-divider />
        </v-col>

        <v-col
          cols="12"
          class="pt-0 pb-3"
          order="6"
        >
          <div class="d-flex align-center justify-center mt-4">
            <div class="footer-card mx-2">
              <icons icon-name="card--paypal" title="PayPal" :width="700 / cardSizer" :height="501 / cardSizer" />
            </div>
            <div class="footer-card mx-2">
              <icons icon-name="card--visa" title="VISA" :width="750 / cardSizer" :height="471 / cardSizer" />
            </div>
            <div class="footer-card mx-2">
              <icons icon-name="card--mastercard" title="Mastercard" :width="482.51 / cardSizer" :height="374 / cardSizer" />
            </div>
            <div class="footer-card mx-2">
              <icons icon-name="card--maestro" title="Maestro" :width="482.6 / cardSizer" :height="374.31 / cardSizer" />
            </div>
          </div>
        </v-col>

        <v-col
          v-if="tpstats"
          cols="12"
          sm="12"
          class="pb-4 pt-0 d-flex align-center flex-column"
          order="8"
        >
          <div>
          <a :href="tpstats.links.profileUrl" class=" trustpilot-link" target="_blank">
            <div class="d-inline-flex">
              <span v-text="translation.all_pages.footer.trustpilot_pre_number + ' '" />
              <span class="px-1">
                <b v-text="' ' + tpstats.businessUnit.numberOfReviews.total + ' '" />
              </span>
              <span class="pr-1" v-text="' ' + translation.all_pages.footer.trustpilot_post_number + ' '" />
              <span style="margin-top: -1px;">
                <icons
                  icon-name="trustpilot"
                  height="20"
                  width="80"
                />
              </span>
            </div>
            <div class="d-flex justify-center mt-1">
              <icons
                :icon-name="'tstar' + tpstats.businessUnit.stars"
                height="20"
                width="92"
              />
              <span
                v-text="' ' + tpstats.starsString"
              />
            </div>
          </a>
          </div>
        </v-col>

        <v-col
          cols="12"
          class="py-0"
          order="9"
        >
          <v-divider />
        </v-col>
      </v-row>
    </div>

    <div class="theme3 footer-sub width-100 py-3">
      <div class="layout-container">
        <v-row class="mx-0">
          <v-col
            cols="12"
            sm="4"
            offset-sm="4"
            class="d-flex flex-row justify-center px-0"
          >
            <div
              v-for="(item, key) in social"
              :key="key"
            >
              <v-btn
                fab
                x-small
                :class="[
                  'elevation-0 mr-3',
                  'footer-social footer-social--' + item.class
                ]"
                :href="item.link"
                target="_blank"
              >
                <v-icon
                  v-if="item.icon !== 'twitter'"
                  color="theme5"
                  v-text="item.icon"
                />
                <icons
                  v-if="item.icon === 'twitter'"
                  class="twitter-icon"
                  color="theme5"
                  icon-name="twitter-x"
                />
              </v-btn>
            </div>
          </v-col>
          <v-col
            v-if="settings.site_name"
            cols="12"
            sm="4"
            class="d-flex align-center justify-end jigsaw-text-small jigsaw-theme1"
          >
            <span>
              &copy; Copyright {{ settings.site_name }} {{ year }}
            </span>
          </v-col>
        </v-row>
      </div>
    </div>
    <jigsaw-cookies />
  </v-footer>
</template>

<script>
import { postObject } from '@/utils/api/api'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import icons from '@/components/icons/icons'
import JigsawCookies from '@/components/cookies/cookies'
export default {
  name: 'FooterPartial',
  components: {
    icons,
    JigsawCookies
  },
  mixins: [validationMixin],
  validations: {
    userSubscribeForm: {
      fname: { required },
      lname: { required },
      email: { email, required },
      coffee: {},
      tea: {}
    }
  },
  data: () => ({
    year: new Date().getFullYear(),
    //
    safari: false,
    //
    settings: {},
    navigation: [],
    //
    subscribeDialog: false,
    userSubscribe: '',
    userSubscribeState: false,
    userSubscribed: false,
    userSubscribeForm: false,
    links: false,
    privacy_url: false,
    //
    tpstats: false,
    cardSizer: 14
  }),
  //
  computed: {
    translation () {
      return this.$store.getters.getTranslation
    },
    userSubscribeEmailErrors () {
      const errors = []
      if (!this.$v.userSubscribeForm.email.$dirty) return errors
      !this.$v.userSubscribeForm.email.email && errors.push('Must be valid e-mail.')
      !this.$v.userSubscribeForm.email.required && errors.push('Please eneter your email address.')
      return errors
    },
    userSubscribeFnameErrors () {
      const errors = []
      if (!this.$v.userSubscribeForm.fname.$dirty) return errors
      !this.$v.userSubscribeForm.fname.required && errors.push('Please eneter your first name.')
      return errors
    },
    userSubscribeLnameErrors () {
      const errors = []
      if (!this.$v.userSubscribeForm.lname.$dirty) return errors
      !this.$v.userSubscribeForm.lname.required && errors.push('Please eneter your last name.')
      return errors
    }
  },
  //
  created () {
    // Settings
    this.$store.watch(
      (state, getters) => getters.getSiteLinks, (result) => {
        this.links = result
        this.privacy_url = this.links.privacy_url !== '' ? this.links.privacy_url : false
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.gettpStats, (result) => {
        this.tpstats = result
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getSiteSettings, (result) => {
        this.settings = result
        this.getSocial()
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getFooterMenu, (result) => {
        this.navigation = result
      }, {
        immediate: true
      }
    )
  },
  methods: {
    getSocial () {
      const social = []
      if (this.settings.facebookUser) {
        social.push({
          icon: 'fab fa-facebook-f',
          class: 'facebook',
          link: 'https://www.facebook.com/' + this.settings.facebookUser
        })
      }
      if (this.settings.twitterUser) {
        social.push({
          icon: 'twitter',
          class: 'twitter',
          link: 'https://twitter.com/' + this.settings.twitterUser
        })
      }
      if (this.settings.instagramUser) {
        social.push({
          icon: 'fab fa-instagram',
          class: 'instagram',
          link: 'https://www.instagram.com/' + this.settings.instagramUser
        })
      }
      if (this.settings.pinterestUser) {
        social.push({
          icon: 'fab fa-pinterest-p',
          class: 'pinterest',
          link: 'https://www.pinterest.co.uk/' + this.settings.pinterestUser
        })
      }

      this.social = social
    },
    openDialog () {
      this.subscribeDialog = true
      this.userSubscribeForm = {
        fname: '',
        lname: '',
        email: '',
        coffee: true,
        tea: true,
        list: this.settings.mailchimp_list
      }
    },
    closeDialog () {
      this.subscribeDialog = false

      setTimeout(() => {
        this.$v.userSubscribeForm.$reset()
        this.userSubscribeState = false
        this.userSubscribed = false
        this.userSubscribeForm = false
      }, 200)
    },
    //
    subscrbeAction () {
      this.$v.userSubscribeForm.$touch()
      if (!this.$v.userSubscribeForm.$anyError) {
        this.userSubscribeState = true

        postObject('siteUserSubscribe', this.userSubscribeForm).then(response => {
          if (response.data) {
            this.userSubscribeState = false
            this.userSubscribed = true
          }
          setTimeout(() => {
            this.closeDialog()
          }, 30 * 1000)
        })
      }
    }
  }
}
</script>
