<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    temporary
    left
  >
    <div class="navigation">
      <div class="navigation--header">
        <router-link
          :to="{path: '/'}"
          class="navigation--header__link"
        >
          <icons
            class="navigation--header__logo"
            icon-name="logo"
          />
        </router-link>
        <v-divider class="width-100 mx-3" />
      </div>
      <div class="navigation--scroller">
        <div
          v-for="(item, key) in menuItems"
          :key="key"
          class="navigation-item"
        >
          <router-link
            :to="{ path: createUrl(item) }"
            class="navigation-link is-parent"
            active-class="is-active--parent"
            exact-active-class="is-active"
          >
            <div>{{ item.title }}</div>
          </router-link>
          <div
            v-if="item.children && item.children.length > 0"
            class="navigation-sub"
          >
            <div
              v-for="(child, ckey) in item.children"
              :key="ckey"
              class="navigation-item"
            >
              <router-link
                v-if="child.expand !== '1'"
                :to="{ path: createUrl(item, child) }"
                class="navigation-link is-parent"
                active-class="is-active"
                exact-active-class="is-active"
              >
                <div>{{ child.title }}</div>
              </router-link>
              <div
                v-else
                :class="[
                  'navigation-link is-parent d-flex justify-space-between',
                  expandMenuItems[child.id] && expandMenuItems[child.id].expanded ? 'is-active' : ''
                ]"
                @click="toggleExpand(child)"
              >
                <div>{{ child.title }}</div>
                <v-icon
                  :class="[
                    'navigation-epxand-icon',
                    expandMenuItems[child.id] && expandMenuItems[child.id].expanded ? 'is-expanded' : ''
                  ]"
                  v-text="'mdi-chevron-down'"
                />
              </div>
              <!-- sub menu -->
              <div
                class="navigation-sub-wrapper"
                :style="[ child.expand === '1' && expandMenuItems[child.id] ? { height: expandMenuItems[child.id].height + 'px' } : '' ]"
              >
                <div
                  v-if="child.children && child.children.length > 0"
                  ref="expandMax"
                  :data-id="child.id"
                  :class="[
                    'navigation-sub',
                    child.expand === '1' ? 'to-expand' : '',
                    ''
                  ]"
                >
                  <div
                    v-for="(sub, skey) in child.children"
                    :key="skey"
                    class="navigation-item"
                  >
                    <router-link
                      :to="{ path: createUrl(item, child, sub) }"
                      class="navigation-link"
                      exact-active-class="is-active"
                    >
                      <div>{{ sub.title }}</div>
                    </router-link>
                  </div>
                  <!-- Add all if item.expand -->
                  <!-- {{ typeof child.expand }} -->
                  <div
                    v-if="child.expand === '1'"
                    class="navigation-item"
                  >
                    <router-link
                      :to="{ path: createUrl(item, child) }"
                      class="navigation-link is-parent"
                      exact-active-class="is-active"
                    >
                      <div>All {{ child.title }}</div>
                    </router-link>
                  </div>
                </div>
              </div>
              <!-- end of sub menu -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
// import { findIndex } from 'lodash'
import icons from '@/components/icons/icons'

export default {
  name: 'FsMenu',
  //
  components: {
    icons
  },
  //
  data: () => ({
    drawer: false,
    menuItems: [],
    baseMenu: [],
    expandMenuItems: [],
    settings: false,
    storeLink: '',
    blogLink: ''
  }),
  //
  watch: {
    drawer: {
      immediate: true,
      deep: true,
      handler (val) {
        this.$store.commit('setShowMenu', val)
      }
    }
  },
  //
  created () {
    // Menu State
    this.$store.watch(
      (state, getters) => getters.getMenuShow, (result) => {
        // this.getChildHeight()
        this.drawer = result

        if (!this.drawer) {
          this.resetExpanded(this.expandMenuItems)
        }
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getSiteMenu, (result) => {
        this.baseMenu = result
        this.processStoreNavigation(this.baseMenu)
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getSiteLinks, (result) => {
        this.storeLink = result.store_url
        this.blogLink = result.blog_url

        this.processStoreNavigation(this.baseMenu)
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getContentLoaded, (result) => {
        if (result) {
          this.getChildHeight()
        }
      }, {
        immediate: true
      }
    )
  },
  methods: {
    createUrl (item, child, sub) {
      let url = item.url

      if (child) {
        url = url + child.url
      }

      if (child && sub) {
        url = url + sub.url
      }

      return url
    },
    processStoreNavigation (menu) {
      if (this.storeLink !== '' && this.baseMenu.length > 0) {
        menu.forEach(item => {
          if (item.url === this.storeLink && item.children.length > 0) {
            item.children.forEach(children => {
              if (children.expand === '1') {
                children.expanded = false
                const menuItem = Object.assign({}, children)
                menuItem.size = 1000
                menuItem.height = 0

                this.expandMenuItems[children.id] = menuItem
              }
            })
          }
        })
      }

      this.menuItems = menu
    },
    getChildHeight () {
      if (this.$refs.expandMax && this.$refs.expandMax.length > 0) {
        const elements = this.$refs.expandMax

        elements.forEach(element => {
          if (element.classList.contains('to-expand')) {
            if (this.expandMenuItems[element.dataset.id]) {
              this.expandMenuItems[element.dataset.id].size = element.clientHeight
            }
          }
        })
      }
    },
    toggleExpand (item) {
      this.getChildHeight()
      if (!this.expandMenuItems[item.id].expanded) {
        this.expandMenuItems[item.id].expanded = true
        this.expandMenuItems[item.id].height = this.expandMenuItems[item.id].size
      } else {
        this.expandMenuItems[item.id].expanded = false
        this.expandMenuItems[item.id].height = 0
      }

      this.$forceUpdate()
    },
    resetExpanded (list) {
      if (list && list.length > 0) {
        list.forEach(item => {
          item.expanded = false
          item.height = 0
        })
      }
    }
  }
}
</script>
