// this.$store.watch(
//   (state, getters) => getters.getSiteSettings, (result) => {
//     this.clientID = result.paypal_env === 'sandbox' ? result.paypal_sandbox : result.paypal_production

//     const script = document.createElement('script')
//     script.src = 'https://www.paypal.com/sdk/js?client-id=' + this.clientID + '&currency=GBP&components=buttons,hosted-fields,funding-eligibility'
//     script.addEventListener('load', this.setLoaded)
//     document.body.appendChild(script)
//   }, {
//     immediate: true
//   }
// )
export default {
  methods: {
    checkForPaypal () {
      return this.$store.state.paypalLoaded
    },
    //
    loadPayPalScript (result) {
      if (!this.checkForPaypal()) {
        if (result && result.paypal_env) {
          const clientID = result.paypal_env === 'sandbox' ? result.paypal_sandbox : result.paypal_production

          const script = document.createElement('script')
          script.src = 'https://www.paypal.com/sdk/js?client-id=' + clientID + '&currency=GBP&components=buttons,funding-eligibility&commit=true&vault=true'
          script.id = 'paypalCheckout'
          script.addEventListener('load', this.$store.commit('setPaypalLoaded', true))
          document.body.appendChild(script)
        }
      }
    },

    loadPayPalSubscriptionScript (result) {
      if (!this.checkForPaypal()) {
        if (result && result.paypal_env) {
          const clientID = result.paypal_env === 'sandbox' ? result.paypal_sandbox : result.paypal_production

          const script = document.createElement('script')
          script.src = 'https://www.paypal.com/sdk/js?client-id=' + clientID + '&currency=GBP&components=buttons,funding-eligibility&vault=true&intent=subscription'
          script.id = 'paypalSubscription'
          script.addEventListener('load', this.$store.commit('setPaypalLoaded', true))
          document.body.appendChild(script)
        }
      }
    },

    removePaypalScript (id) {
      this.$store.commit('setPaypalLoaded', false)
      const elem = document.getElementById(id)
      if (elem) {
        elem.parentNode.removeChild(elem)
      }
    },
    // &vault=true&intent=subscription

    elementReady (selector) {
      return new Promise((resolve) => {
        const el = document.querySelector(selector)
        if (el) {
          resolve(el)
        }
        new MutationObserver((mutationRecords, observer) => {
          // Query for elements matching the specified selector
          Array.from(document.querySelectorAll(selector)).forEach((element) => {
            resolve(element)
            // Once we have resolved we don't need the observer anymore.
            observer.disconnect()
          })
        })
          .observe(document.documentElement, {
            childList: true,
            subtree: true
          })
      })
    }
  }
}
