<template>
  <div
    v-if="item && (item.id || item.item_id)"
    :class="[
      small ? 'image-50-small' : ''
    ]"
  >
    <v-img
      v-if="item.images && item.images.length > 0"
      class="grey darken-2"
      :src="path + item.images[0]"
      aspect-ratio="1"
      :alt="item.name"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          />
        </v-row>
      </template>
    </v-img>
    <v-img
      v-else
      aspect-ratio="1"
      class="theme4"
    >
      <template v-slot:default>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <div>
            <v-icon
              color="theme2"
              v-text="'far fa-image'"
            />
          </div>
        </v-row>
      </template>
    </v-img>
  </div>
</template>

<script>
export default {
  name: 'BasketImage',
  //
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
    path: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>
