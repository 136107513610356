<template>
  <div
    v-if="settings && crumbs && crumbs.length > 1 && translation"
    class="layout-container"
  >
    <div class="d-flex my-2 mx-3">
      <div
        v-if="crumbs.length > 2"
        class="d-inline-flex d-sm-none jigsaw-body-text pr-2"
        v-text="'...'"
      />
      <div
        v-for="(crumb, key) in crumbs"
        :key="key"
        class="jigsaw-body-text jigsaw-breadcrumb"
      >
        <span v-if="!crumb.disabled">
          <router-link
            class="breadcrumb-link"
            :to="{ path: crumb.path }"
            exact-active-class=""
            active-class=""
            v-text="crumb.label"
          />
          <v-icon v-text="'mdi-chevron-right'" />
        </span>
        <span
          v-else
          class="breadcrumb-label"
          v-text="crumb.label"
        />
      </div>
    </div>
  </div>
</template>

<script>
import forEach from 'lodash/forEach'
export default {
  name: 'BreadcrumbComponent',
  data: () => ({
    crumbs: [],
    settings: false
  }),
  watch: {
    $route () {
      if (this.translation) {
        this.getCrumbs()
      }
    }
  },
  computed: {
    translation () {
      return this.$store.getters.getTranslation
    }
  },
  mounted () {
    // Settings
    this.$store.watch(
      (state, getters) => getters.getSiteLinks, (result) => {
        this.settings = result

        if (this.translation) {
          this.getCrumbs()
        }
      }, {
        immediate: true
      }
    )
  },
  methods: {
    getCrumbs () {
      let paths = this.$route.path.split('/')
      paths = paths.filter(Boolean)

      const crumbs = [
        {
          label: this.translation.general.labels.home_breadcrumb,
          disabled: true,
          path: '/'
        }
      ]

      let base = ''

      if (this.settings.search_url === '/' + paths[0]) {
        paths.pop()
      }

      if (this.settings.view_order_url === '/' + paths[0] || this.settings.checkout_end_url === '/' + paths[0]) {
        paths.pop()
        paths.pop()
      }

      if (this.settings.checkout_failed_url === '/' + paths[0] || this.settings.checkout_failed_url === '/' + paths[0]) {
        paths.pop()
        paths.pop()
      }

      if (paths.length > 0) {
        crumbs[0].disabled = false
        forEach(paths, (item) => {
          base += '/' + item

          crumbs.push({
            label: item.replace(/-/g, ' '),
            disabled: false,
            path: base
          })
        })

        crumbs[crumbs.length - 1].disabled = true
      }

      this.crumbs = crumbs
    }
  }
}
</script>
