import {
  api,
  remote
} from './config'
import { forEach } from 'lodash'

export const getData = (url) => api(url)

export const getPostId = (url, id) => {
  const data = new FormData()

  data.append('id', id)
  return api.post(url, data)
}

export const postArray = (url, dataArr) => {
  const data = new FormData()

  forEach(dataArr, (item) => {
    data.append(item.id, item.model)
  })

  return api.post(url, data)
}

export const postObject = (url, dataArr) => {
  const data = new FormData()

  forEach(dataArr, (item, key) => {
    data.append(key, item)
  })

  return api.post(url, data)
}

export const postData = (url, dataArr) => {
  const data = new FormData()

  forEach(dataArr, (item, key) => {
    data.append(key, item)
  })

  return api.post(url, data)
}

export const call = async function (url) {
  if (url) {
    const data = await (await (fetch(url)
      .then(res => {
        return res.json()
      })
      .catch(err => {
        return err
      })
    ))
    return data
  }
}

// remote post
export const remotePostData = (url, dataArr) => {
  const data = new FormData()

  forEach(dataArr, (item, key) => {
    data.append(key, item)
  })

  return remote.post(url, data)
}
