<template>
  <v-menu
    v-if="user && translation"
    v-model="accountShow"
    :close-on-content-click="true"
    offset-y
    offset-x
    origin="top right"
    nudge-left="280"
    min-width="280"
    transition="scale-transition"
    content-class="basket basket--header"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="d-sm-flex d-none"
        icon
        large
        v-bind="attrs"
        v-on="on"
        @click="openAccountDialog()"
      >
        <Icons icon-name="header-user-filled" />
      </v-btn>
      <v-btn
        class="d-sm-none d-flex"
        icon
        v-bind="attrs"
        v-on="on"
        @click="openAccountDialog()"
      >
        <Icons icon-name="header-user-filled" />
      </v-btn>
    </template>
    <v-card class="jigsaw-card">
      <v-card-title class="align-center pa-2 theme5">
        <icons
          right
          icon-name="header-user"
        />
        <span
          class="jigsaw-theme1 jigsaw-text-script jigsaw-text-h1 pl-2 mb-n1"
          v-text="translation.all_pages.header.account_label"
        />
        <v-spacer />
        <v-btn
          class="basket-close"
          icon
          @click="closeAccountDialog()"
        >
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-2 px-0">
        <div class="navigation">
          <div class="navigation-item">
            <router-link
              v-if="settings.account_url"
              class="navigation-link align-center pa-2"
              :to="{ path: settings.account_url }"
            >
              <Icons icon-name="account-details" />
              <span
                class="jigsaw-card-text align-center pl-2"
                v-text="translation.account.account_details.details_title"
              />
            </router-link>
            <router-link
              v-if="settings.orders_url"
              class="navigation-link align-center pa-2"
              :to="{ path: settings.orders_url }"
            >
              <v-badge
                v-if="user.orders && checkOrderBadge(user.orders) > 0"
                :content="checkOrderBadge(user.orders)"
                :value="checkOrderBadge(user.orders)"
                color="secondary"
                overlap
                left
              >
                <Icons icon-name="delivery" />
              </v-badge>
              <Icons
                v-else
                icon-name="delivery"
              />
              <span
                class="jigsaw-card-text align-center pl-2"
                v-text="translation.account.order_history.order_title"
              />
            </router-link>
            <router-link
              v-if="settings.subscription_url"
              class="navigation-link align-center pa-2"
              :to="{ path: settings.subscription_url }"
            >
              <v-badge
                v-if="user.subscriptions && user.subscriptions.length > 0"
                :content="user.subscriptions.length"
                :value="user.subscriptions.length"
                color="secondary"
                overlap
                left
              >
                <Icons icon-name="subscription" />
              </v-badge>
              <Icons
                v-else
                icon-name="subscription"
              />
              <span
                class="jigsaw-card-text align-center pl-2"
                v-text="translation.account.subscriptions.subscription_title"
              />
            </router-link>
            <router-link
              v-if="settings.pantry_url"
              class="navigation-link align-center pa-2"
              :to="{ path: settings.pantry_url }"
            >
              <v-badge
                v-if="user.pantry && user.pantry.length > 0"
                :content="user.pantry.length"
                :value="user.pantry.length"
                color="secondary"
                overlap
                left
              >
                <Icons icon-name="pantry" />
              </v-badge>
              <Icons
                v-else
                icon-name="pantry"
              />
              <span
                class="jigsaw-card-text align-center pl-2"
                v-text="translation.account.favourites.favourites_title"
              />
            </router-link>
            <router-link
              v-if="settings.account_vouchers_url"
              class="navigation-link align-center pa-2"
              :to="{ path: settings.account_vouchers_url }"
            >
              <Icons icon-name="gift" />
              <span
                class="jigsaw-card-text align-center pl-2"
                v-text="translation.account.gift_vouchers.gift_vouchers_title"
              />
            </router-link>
            <router-link
              v-if="settings.addresses_url"
              class="navigation-link align-center pa-2"
              :to="{ path: settings.addresses_url }"
            >
              <Icons icon-name="book" />
              <span
                class="jigsaw-card-text align-center pl-2"
                v-text="translation.account.address_book.address_book_title"
              />
            </router-link>
            <v-divider class="pb-2 mt-2" />
            <div
              class="navigation-link align-center pa-2 pointer"
              color="primary"
              link
              @click="logout()"
            >
              <v-icon
                left
                v-text="'mdi-power-standby'"
              />
              <span
                class="jigsaw-card-text align-center pl-2"
                v-text="translation.all_pages.header.account_logout_label"
              />
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>

  <v-dialog
    v-else
    v-model="dialog"
    persistent
    scrollable
    max-width="800"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="d-sm-flex d-none"
        icon
        large
        v-bind="attrs"
        v-on="on"
      >
        <Icons icon-name="header-user" />
      </v-btn>
      <v-btn
        class="d-sm-none d-flex"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <Icons icon-name="header-user" />
      </v-btn>
    </template>
    <v-card v-if="translation">
      <v-card-title class="align-center py-2 pl-5 pr-2 theme5">
        <span v-text="translation.all_pages.account_popup.popup_title" />
        <v-spacer />
        <v-btn
          class="basket-close"
          icon
          @click="closeDialog()"
        >
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="py-0">
        <v-row class="my-4">
          <v-col
            cols="12"
            sm="6"
            class="d-flex pa-0"
          >
            <v-row
              no-gutters
              class="d-flex align-stretch pl-sm-3 pr-sm-6"
            >
              <v-col
                cols="12"
                class="d-flex flex-column"
              >
                <div
                  class="jigsaw-text-script theme1--text jigsaw-text-title text-center pb-1"
                  v-text="translation.all_pages.account_popup.login_title"
                />
                <div
                  v-if="!resetPasswordDialog"
                  class="theme1--text jigsaw-card-text text-center pt-1 pb-3"
                  v-text="translation.all_pages.account_popup.login_subtitle"
                />
                <div
                  v-if="resetPasswordDialog"
                  class="theme1--text jigsaw-card-text text-center pt-1 pb-3"
                  v-text="translation.all_pages.account_popup.reset_password_text"
                />

                <div
                  v-if="!resetPasswordDialog"
                  class="mb-3"
                >
                  <v-text-field
                    v-model="signinForm.email"
                    :label="translation.general.form_inputs.email_address"
                    outlined
                    dense
                    type="email"
                    autocomplete="none"
                    :success="$v.signinForm.email.$dirty"
                    :error-messages="signinEmailErrors"
                    @blur="$v.signinForm.email.$touch()"
                  />
                  <v-text-field
                    v-model="signinForm.password"
                    :label="translation.general.form_inputs.password"
                    :type="typeSign"
                    :append-icon="typeSign === 'password' ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    dense
                    autocomplete="none"
                    :success="$v.signinForm.password.$dirty"
                    :error-messages="signinPasswordErrors"
                    @blur="$v.signinForm.password.$touch()"
                    @click:append="typeSign === 'password' ? typeSign = 'text' : typeSign = 'password'"
                  />

                  <div
                    v-if="signInError"
                    class="jigsaw-card-text error--text pb-6 text-center"
                    v-text="signInError"
                  />

                  <div class="d-flex justify-center">
                    <v-btn
                      color="success"
                      large
                      :loading="signInState"
                      @click="signIn()"
                    >
                      <span
                        class="jigsaw-button-text"
                        v-text="translation.all_pages.account_popup.login_button"
                      />
                    </v-btn>
                  </div>

                  <div class="d-flex justify-center py-4 px-0 px-sm-3">
                    <v-btn
                      color="theme1"
                      text
                      @click="openResetDialog()"
                    >
                      <span
                        class="jigsaw-card-text"
                        v-text="translation.all_pages.account_popup.reset_password_text"
                      />
                    </v-btn>
                  </div>
                </div>

                <div
                  v-if="resetPasswordDialog"
                  class="mb-auto"
                >
                  <div
                    v-if="!resetFormMessage"
                    class="px-4"
                  >
                    <v-text-field
                      v-model="resetForm.email"
                      :label="translation.general.form_inputs.email_address"
                      outlined
                      dense
                      type="email"
                      autocomplete="none"
                      :success="$v.resetForm.email.$dirty"
                      :error-messages="resetEmailErrors"
                      @blur="$v.resetForm.email.$touch()"
                    />
                  </div>
                  <div
                    v-if="resetFormMessage"
                    class="jigsaw-card-text pa-4"
                    v-text="resetFormMessage"
                  />

                  <div class="d-flex justify-space-around">
                    <v-btn
                      v-if="!resetFormMessage"
                      large
                      color="theme3"
                      class="px-4"
                      @click="closeResetDialog()"
                    >
                      <span
                        class="jigsaw-button-text"
                        v-text="translation.general.buttons.cancel"
                      />
                    </v-btn>
                    <v-btn
                      v-if="!resetFormMessage"
                      large
                      color="primary"
                      class="px-4"
                      :loading="resetFormState"
                      @click="resetPasswordHandle()"
                    >
                      <span
                        class="jigsaw-button-text"
                        v-text="translation.general.buttons.reset"
                      />
                    </v-btn>
                    <v-btn
                      v-if="resetFormMessage"
                      large
                      color="primary"
                      class="px-4"
                      @click="closeResetDialog()"
                    >
                      <span
                        class="jigsaw-button-text"
                        v-text="translation.general.buttons.close"
                      />
                    </v-btn>
                  </div>
                </div>

                <div
                  v-if="settings.account_benefits_url !== '' && !resetPasswordDialog"
                  class="mb-auto mx-auto"
                >
                  <v-btn
                    text
                    link
                    class="multiline-button"
                    @click="goToBenefits()"
                  >
                    <div
                      class="text-capitalize-first"
                      v-text="translation.all_pages.account_popup.account_benefits_text"
                    />
                  </v-btn>

                  <div class="black--text jigsaw-body-text my-2" v-html="parseTransList(translation.all_pages.account_popup.account_benefits_list)" />
                </div>
              </v-col>
            </v-row>
            <v-divider
              class="d-none d-sm-block"
              vertical
            />
          </v-col>

          <v-col
            cols="12"
            class="d-block d-sm-none pt-6 pb-2"
          >
            <v-divider />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="d-flex align-stretch pa-0"
          >
            <v-row
              no-gutters
              class="d-flex align-stretch pr-sm-3 pl-sm-6"
            >
              <v-col
                cols="12"
                class="d-flex flex-column"
              >
                <div
                  class="jigsaw-text-script theme1--text jigsaw-text-title text-center pb-1"
                  v-text="translation.all_pages.account_popup.register_title"
                />
                <div
                  class="theme1--text jigsaw-card-text text-center pt-1 pb-3"
                  v-text="translation.all_pages.account_popup.register_subtitle"
                />
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-select
                      v-model="signupForm.title"
                      :label="translation.general.form_inputs.name_prefix"
                      name="title"
                      :items="userTitles"
                      item-text="label"
                      item-value="value"
                      outlined
                      dense
                      :success="$v.signupForm.title.$dirty"
                      :error-messages="signupTitleErrors"
                      error-count="3"
                      @blur="$v.signupForm.title.$touch()"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    class="pl-sm-3"
                  >
                    <v-text-field
                      v-model="signupForm.first_name"
                      :label="translation.general.form_inputs.first_name"
                      outlined
                      dense
                      type="text"
                      autocomplete="none"
                      :success="$v.signupForm.first_name.$dirty"
                      :error-messages="signupFirstErrors"
                      error-count="3"
                      @blur="$v.signupForm.first_name.$touch()"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="signupForm.last_name"
                      :label="translation.general.form_inputs.last_name"
                      outlined
                      dense
                      type="text"
                      autocomplete="none"
                      :success="$v.signupForm.last_name.$dirty"
                      :error-messages="signupLastErrors"
                      error-count="3"
                      @blur="$v.signupForm.last_name.$touch()"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="signupForm.contact"
                      :label="translation.general.form_inputs.phone_number"
                      outlined
                      dense
                      autocomplete="none"
                      :hint="translation.general.form_inputs.phone_number_hint"
                      persistent-hint
                      :success="$v.signupForm.contact.$dirty"
                      :error-messages="signupContactErrors"
                      error-count="3"
                      @blur="$v.signupForm.contact.$touch()"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="signupForm.email"
                      :label="translation.general.form_inputs.email_address"
                      outlined
                      dense
                      type="email"
                      autocomplete="none"
                      :success="$v.signupForm.email.$dirty"
                      :error-messages="signupEmailErrors"
                      error-count="3"
                      @blur="$v.signupForm.email.$touch()"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    class="password-strength"
                  >
                    <div
                      class="password-strength-meter"
                      :data-strength="passwordStrength * 20"
                    />
                    <v-text-field
                      v-model="signupForm.password"
                      :label="translation.general.form_inputs.password"
                      outlined
                      dense
                      :type="typeA"
                      :append-icon="typeA === 'password' ? 'mdi-eye' : 'mdi-eye-off'"
                      :hint="translation.general.form_inputs.password_hint"
                      persistent-hint
                      autocomplete="none"
                      :success="$v.signupForm.password.$dirty"
                      :error-messages="signupPasswordErrors"
                      error-count="3"
                      @input="strengthCheck(signupForm.password)"
                      @blur="$v.signupForm.password.$touch()"
                      @click:append="typeA === 'password' ? typeA = 'text' : typeA = 'password'"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="signupForm.confirm_password"
                      :label="translation.general.form_inputs.confirm_passowrd"
                      outlined
                      dense
                      :type="typeB"
                      :append-icon="typeB === 'password' ? 'mdi-eye' : 'mdi-eye-off'"
                      :hint="translation.general.form_inputs.confirm_passowrd_hint"
                      persistent-hint
                      autocomplete="none"
                      :success="$v.signupForm.confirm_password.$dirty"
                      :error-messages="signupConfirmErrors"
                      error-count="3"
                      @blur="$v.signupForm.confirm_password.$touch()"
                      @click:append="typeB === 'password' ? typeB = 'text' : typeB = 'password'"
                    />
                  </v-col>

                  <v-col cols="12">
                    <div
                      v-if="signUpError"
                      class="jigsaw-card-text error--text pb-6 text-center"
                      v-text="signUpError"
                    />
                  </v-col>

                  <v-col v-if="siteSettings.mailchimp_subscribe !== '0'" cols="12">
                    <div class="d-flex justify-center mb-4">
                      <v-checkbox
                        v-model="signupForm.mailing_list"
                        class="ma-0 pa-0"
                        :true-value="1"
                        :false-value="0"
                        :disabled="signUpState"
                        hide-details
                      >
                        <template v-slot:label>
                          <div class="d-flex align-center jigsaw-theme1">
                            <Icons
                              class="mr-2 rotate-90"
                              icon-name="bean"
                              height="24"
                              width="24"
                              color="theme2"
                            />
                            <span v-text="translation.general.labels.subscribe_to_our_mailing_list_text" />
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      color="primary"
                      large
                      :disabled="signUpState"
                      :loading="signUpState"
                      @click="signUp()"
                    >
                      <span
                        class="jigsaw-button-text"
                        v-text="translation.all_pages.account_popup.register_account_button"
                      />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { keys, pickBy } from 'lodash'
import { getData, postObject } from '../utils/api/api'
import { validationMixin } from 'vuelidate'
import { required, email, minLength, sameAs, helpers } from 'vuelidate/lib/validators'
import passwordComplexity from '../utils/customValidators/passwordComplexity'
import general from '../utils/general/general'
import Icons from '@/components/icons/icons'

const isPhone = helpers.regex('numeric', /\(?(0[1-7][0-9]{3}\)?\s?\d{6})$/)

export default {
  name: 'StoreAccount',
  components: {
    Icons
  },
  mixins: [general, validationMixin],
  //
  validations: {
    signinForm: {
      email: { required, email },
      password: { required }
    },
    signupForm: {
      title: { required },
      first_name: { required },
      last_name: { required },
      contact: {
        required,
        minLength: minLength(11),
        phoneValid: isPhone
      },
      email: { required, email },
      password: { required, minLength: minLength(8), passwordComplexity },
      confirm_password: { required, sameAsPassword: sameAs('password') },
      mailing_list: {},
    },
    resetForm: {
      email: { required, email }
    }
  },
  data: () => ({
    //
    settings: {},
    user: false,
    userTitles: false,
    //
    accountShow: false,
    dialog: false,
    //
    signinForm: {},
    typeSign: 'password',
    resetPasswordDialog: false,
    resetForm: {},
    resetFormMessage: false,
    resetFormState: false,
    //
    signInState: false,
    signInError: false,
    signupForm: {},
    signupFormDefault: {
      title: '',
      first_name: '',
      last_name: '',
      email: '',
      contact: '',
      password: '',
      confirm_password: '',
      mailing_list: '1'
    },
    signUpState: false,
    signUpError: false,
    typeA: 'password',
    typeB: 'password',
    strengthScore: {
      upper: false,
      lower: false,
      number: false,
      special: false,
      length: false
    },
    strength: '1 uppercase, 1 lowercase, 1 number, 1 special character, 8 characters',
    passwordStrength: 0
  }),
  //
  computed: {
    translation () {
      return this.$store.getters.getTranslation
    },
    siteSettings () {
      return this.$store.getters.getSiteSettings
    },
    signinEmailErrors () {
      const errors = []
      if (!this.$v.signinForm.email.$dirty) return errors
      !this.$v.signinForm.email.email && errors.push('Must be valid e-mail.')
      !this.$v.signinForm.email.required && errors.push('Your email address is required to sign in.')
      return errors
    },
    signinPasswordErrors () {
      const errors = []
      if (!this.$v.signinForm.password.$dirty) return errors
      !this.$v.signinForm.password.required && errors.push('Your password is required to sign in.')
      return errors
    },
    //
    signupTitleErrors () {
      const errors = []
      if (!this.$v.signupForm.title.$dirty) return errors
      !this.$v.signupForm.title.required && errors.push('A title is required.')
      return errors
    },
    signupFirstErrors () {
      const errors = []
      if (!this.$v.signupForm.first_name.$dirty) return errors
      !this.$v.signupForm.first_name.required && errors.push('A store link is required.')
      return errors
    },
    signupLastErrors () {
      const errors = []
      if (!this.$v.signupForm.last_name.$dirty) return errors
      !this.$v.signupForm.last_name.required && errors.push('A store link is required.')
      return errors
    },
    signupContactErrors () {
      const errors = []
      if (!this.$v.signupForm.contact.$dirty) return errors
      !this.$v.signupForm.contact.required && errors.push('A phone number is required.')
      !this.$v.signupForm.contact.phoneValid && errors.push('Must be valid phone number')
      !this.$v.signupForm.contact.minLength && errors.push('Phone number is too short, must be at least 11 digits long')
      return errors
    },
    signupEmailErrors () {
      const errors = []
      if (!this.$v.signupForm.email.$dirty) return errors
      !this.$v.signupForm.email.email && errors.push('Must be valid e-mail.')
      !this.$v.signupForm.email.required && errors.push('An email address is required.')
      return errors
    },
    signupPasswordErrors () {
      const errors = []
      if (!this.$v.signupForm.password.$dirty) return errors
      !this.$v.signupForm.password.required && errors.push('A password is required')
      !this.$v.signupForm.password.minLength && errors.push('At least 8 characters long')
      !this.$v.signupForm.password.passwordComplexity && errors.push(this.strength + ' are still required')
      return errors
    },
    signupConfirmErrors () {
      const errors = []
      if (!this.$v.signupForm.confirm_password.$dirty) return errors
      !this.$v.signupForm.confirm_password.required && errors.push('A confirm password is required.')
      !this.$v.signupForm.confirm_password.sameAsPassword && errors.push('The passwords must match.')
      return errors
    },
    //
    resetEmailErrors () {
      const errors = []
      if (!this.$v.resetForm.email.$dirty) return errors
      !this.$v.resetForm.email.email && errors.push('Must be valid e-mail.')
      !this.$v.resetForm.email.required && errors.push('Your email address is required to reset your password.')
      return errors
    }
  },
  //
  created () {
    // Settings
    this.$store.watch(
      (state, getters) => getters.getSiteLinks, (result) => {
        this.settings = result
      }, {
        immediate: true
      }
    )

    // User Titles
    this.$store.watch(
      (state, getters) => getters.getUserTitles, (result) => {
        this.userTitles = result
      }, {
        immediate: true
      }
    )

    // User
    this.$store.watch(
      (state, getters) => getters.getUser, (result) => {
        if (result && result.user) {
          this.user = result
        } else {
          this.user = false
        }
      }, {
        immediate: true
      }
    )
  },
  //
  methods: {
    // Process Action
    //
    openAccountDialog () {
      this.signupForm = JSON.parse(JSON.stringify(this.signupFormDefault))

      this.signinForm = {
        email: '',
        password: ''
      }

      setTimeout(() => {
        this.dialog = true
        this.resetPasswordDialog = false
      }, 1)
    },
    //
    strengthCheck (password) {
      const strength = {
        upper: /[A-Z]/.test(password),
        lower: /[a-z]/.test(password),
        number: /\d/.test(password),
        special: /[#?!@$%^&*-]/.test(password),
        length: password.length > 7
      }

      this.strengthScore = JSON.parse(JSON.stringify(strength))

      const active = keys(pickBy(this.strengthScore))
      this.passwordStrength = active.length

      let message = [
        strength.upper ? '' : '1 uppercase',
        strength.lower ? '' : '1 lowercase',
        strength.number ? '' : '1 number',
        strength.special ? '' : '1 special character',
        strength.length ? '' : '8 characters'
      ]

      message = message.filter(Boolean)

      this.strength = message.join(', ')
    },
    closeAccountDialog () {
      this.accountShow = false
    },
    //
    closeDialog () {
      this.dialog = false

      setTimeout(() => {
        this.$v.signinForm.$reset()
        this.$v.signupForm.$reset()
        this.signInState = false
        this.signInError = false
        this.signUpState = false
        this.signUpError = false
        this.resetPasswordDialog = false
        this.signinForm = {}
        this.signupForm = {}
        this.passwordStrength = 0
      }, 200)
    },
    //
    goToBenefits () {
      this.closeDialog()

      setTimeout(() => {
        if (this.$route.path !== this.settings.account_benefits_url) {
          this.$router.push({ path: this.settings.account_benefits_url })
        }
      }, 100)
    },
    logout () {
      // userAccountLogout
      getData('account/userAccountLogout').then(response => {
        if (response.data) {
          this.$store.commit('setUser', {})
          this.$store.commit('setOrder', {})
          // this.$store.commit('setBasket', [])
          this.$store.commit('setOrderSteps', [])
          this.closeDialog()

          this.$cookies.remove('aromaUser')

          if (this.$route.path !== '/') {
            this.$router.push({ path: '/' })
          }
        }
      })
    },
    /**
     * Sign In
     */
    signIn () {
      this.$v.signinForm.$touch()
      if (!this.$v.signinForm.$anyError) {
        this.signInState = true
        this.signInError = false
        postObject('account/userAccountSignIn', this.signinForm).then(response => {
          if (response.data) {
            const source = response.data
            if (source.error) {
              this.signInError = source.error
              this.signInState = false
            } else {
              this.$store.commit('setUser', source)
              this.closeDialog()
              if (this.settings.account_url) {
                if (this.$route.path !== this.settings.account_url) {
                  this.$router.push({ path: this.settings.account_url })
                }
              }
            }
          }
        })
      }
    },
    /**
     * Sign Up
     */
    signUp () {
      this.$v.signupForm.$touch()
      if (!this.$v.signupForm.$anyError) {
        if (!this.$v.signupForm.mailing_list.$model) {
          this.signupForm.mailing_list = 0
        }
        this.signUpState = true
        this.signUpError = false

        postObject('account/userAccountSignUp', this.signupForm).then(response => {
          if (response.data) {
            const source = response.data
            if (source.error) {
              this.signUpError = source.error
              this.signUpState = false
            } else if (source.user) {
              this.$store.commit('setUser', source)
              this.closeDialog()
              if (this.settings.account_url) {
                this.$router.push({ path: this.settings.account_url })
              }
            } else {
              this.signUpError = 'Oops! something went wrong'
              this.signUpState = false
            }
          }

          this.signUpState = false
        })
      }
    },
    /**
     * Reset Password
     */
    openResetDialog () {
      // this.closeDialog()
      this.resetForm = { email: '' }
      this.resetPasswordDialog = true
    },
    closeResetDialog () {
      this.resetPasswordDialog = false
      // this.closeDialog()

      setTimeout(() => {
        this.$v.resetForm.$reset()
        this.resetFormMessage = false
      }, 200)
    },
    resetPasswordHandle () {
      this.$v.resetForm.$touch()
      if (!this.$v.resetForm.$anyError) {
        this.resetFormState = true
        postObject('account/userForgettonPassword', this.resetForm).then(response => {
          if (response.data && response.data.message) {
            this.resetFormState = false
            // this.closeDialog()
            this.resetFormMessage = response.data.message
          } else {
            this.closeResetDialog()
          }
          this.resetFormState = false
        })
      }
    },
    //
    checkOrderBadge (orders) {
      if (orders && orders.length > 0) {
        const op = orders.filter(order => {
          if (order.status === 'Processing') {
            return order
          }
        })

        return op.length
      }

      return orders.length
    }
  }
}
</script>
