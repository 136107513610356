import { isNull, isString, isEmpty } from 'lodash'

export default password => {
  // probably already handled by the required flag
  if (isNull(password) || !isString(password) || isEmpty(password)) {
    return false
  }

  // Minimum of 1 Uppercase Letter
  if (/[A-Z]/.test(password) === false) {
    return false
  }

  // Minimum of 1 Lowercase Letter
  if (/[a-z]/.test(password) === false) {
    return false
  }

  // Minimum of 1 Number
  if (/\d/.test(password) === false) {
    return false
  }

  if (/[#?!@$%^&*-]/.test(password) === false) {
    return false
  }

  // Minimum of 6 Characters
  // separately targeting input length to provide specific error message

  return true
}
