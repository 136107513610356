import Vue from 'vue'
import App from './App.vue'
import router from './utils/router/'
import store from './utils/store/'
import vuetify from './plugins/vuetify'
import sanitizeHTML from 'sanitize-html'
import Meta from 'vue-meta'
import VueCookies from 'vue-cookies'
import VueClipboards from 'vue-clipboards'
import SocialSharing from 'vue-social-sharing'
import Vuelidate from 'vuelidate'
import VueGtag from 'vue-gtag'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import '@mdi/font/css/materialdesignicons.css'
import 'leaflet/dist/leaflet.css'
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective)
Vue.config.productionTip = false
Vue.prototype.$sanitize = sanitizeHTML
Vue.use(VueClipboards)
Vue.use(Vuelidate)
Vue.use(SocialSharing)
Vue.use(Meta)
Vue.use(VueCookies)

Vue.use(VueGtag, {
  bootstrap: false,
});

const toastOptions = {
  toastClassName: 'apollo-toast',
  bodyClassName: 'apollo-toast__body',
  position: 'bottom-center',
  transition: 'Vue-Toastification__bounce',
  maxToasts: 3,
  newestOnTop: true
  // You can set your default options here
}
Vue.use(Toast, toastOptions)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
