import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/content/content.vue')
  },
  {
    path: '/:urla',
    name: 'Content',
    component: () => import('@/content/content.vue')
  },
  {
    path: '/:urla/:urlb',
    name: 'ContentPlus',
    component: () => import('@/content/content.vue')
  },
  {
    path: '/:urla/:urlb/:urlc',
    name: 'ContentExtra',
    component: () => import('@/content/content.vue')
  },
  {
    path: '/:urla/:urlb/:urlc/:urld',
    name: 'ContentExtraPlus',
    component: () => import('@/content/content.vue')
  }
]

const router = new VueRouter({
  scrollBehavior(to) {
    if (to.hash) {
      return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' })
    }
    return window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  mode: 'history',
  routes
})

export default router
