var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","temporary":"","left":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"navigation"},[_c('div',{staticClass:"navigation--header"},[_c('router-link',{staticClass:"navigation--header__link",attrs:{"to":{path: '/'}}},[_c('icons',{staticClass:"navigation--header__logo",attrs:{"icon-name":"logo"}})],1),_c('v-divider',{staticClass:"width-100 mx-3"})],1),_c('div',{staticClass:"navigation--scroller"},_vm._l((_vm.menuItems),function(item,key){return _c('div',{key:key,staticClass:"navigation-item"},[_c('router-link',{staticClass:"navigation-link is-parent",attrs:{"to":{ path: _vm.createUrl(item) },"active-class":"is-active--parent","exact-active-class":"is-active"}},[_c('div',[_vm._v(_vm._s(item.title))])]),(item.children && item.children.length > 0)?_c('div',{staticClass:"navigation-sub"},_vm._l((item.children),function(child,ckey){return _c('div',{key:ckey,staticClass:"navigation-item"},[(child.expand !== '1')?_c('router-link',{staticClass:"navigation-link is-parent",attrs:{"to":{ path: _vm.createUrl(item, child) },"active-class":"is-active","exact-active-class":"is-active"}},[_c('div',[_vm._v(_vm._s(child.title))])]):_c('div',{class:[
                'navigation-link is-parent d-flex justify-space-between',
                _vm.expandMenuItems[child.id] && _vm.expandMenuItems[child.id].expanded ? 'is-active' : ''
              ],on:{"click":function($event){return _vm.toggleExpand(child)}}},[_c('div',[_vm._v(_vm._s(child.title))]),_c('v-icon',{class:[
                  'navigation-epxand-icon',
                  _vm.expandMenuItems[child.id] && _vm.expandMenuItems[child.id].expanded ? 'is-expanded' : ''
                ],domProps:{"textContent":_vm._s('mdi-chevron-down')}})],1),_c('div',{staticClass:"navigation-sub-wrapper",style:([ child.expand === '1' && _vm.expandMenuItems[child.id] ? { height: _vm.expandMenuItems[child.id].height + 'px' } : '' ])},[(child.children && child.children.length > 0)?_c('div',{ref:"expandMax",refInFor:true,class:[
                  'navigation-sub',
                  child.expand === '1' ? 'to-expand' : '',
                  ''
                ],attrs:{"data-id":child.id}},[_vm._l((child.children),function(sub,skey){return _c('div',{key:skey,staticClass:"navigation-item"},[_c('router-link',{staticClass:"navigation-link",attrs:{"to":{ path: _vm.createUrl(item, child, sub) },"exact-active-class":"is-active"}},[_c('div',[_vm._v(_vm._s(sub.title))])])],1)}),(child.expand === '1')?_c('div',{staticClass:"navigation-item"},[_c('router-link',{staticClass:"navigation-link is-parent",attrs:{"to":{ path: _vm.createUrl(item, child) },"exact-active-class":"is-active"}},[_c('div',[_vm._v("All "+_vm._s(child.title))])])],1):_vm._e()],2):_vm._e()])],1)}),0):_vm._e()],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }