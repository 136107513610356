<template>
  <div v-if="!jigsawCookies.jigsaw_cookies && translation" class="jigsaw-cookies">
    <div class="jigsaw-cookies__container">
      <div v-if="showManagae">
        <v-row>
          <v-col
            cols="12"
            class="jigsaw-text-h4 pb-0"
            v-text="translation.all_pages.cookie.cookie_measure_title"
          />
          <v-col
            cols="10"
            class="jigsaw-lead-text pt-0"
            v-text="translation.all_pages.cookie.cookie_measure_text"
          />
          <v-col cols="2" class="jigsaw-lead-text pt-0 d-flex justify-end">
            <v-switch
              v-model="jigsawCookies.jigsaw_analytics_cookie"
              inset
              color="primary"
              class="pa-0 ma-0"
              :false-value="0"
              :true-value="1"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="jigsaw-text-h4 pb-0"
            v-text="translation.all_pages.cookie.cookie_necessary_title"
          />
          <v-col
            cols="12"
            class="jigsaw-lead-text pt-0"
          >
            <div v-text="translation.all_pages.cookie.cookie_necessary_text" />
            <div v-text="translation.all_pages.cookie.cookie_necessary_subtext" />
          </v-col>
        </v-row>
        <v-row class="ma-3">
          <v-col cols="12" class="d-flex justify-end py-0">
            <v-btn
              color="theme4"
              class="mx-2"
              @click="showManagae = !showManagae"
              v-text="translation.general.buttons.cancel"
            />
            <v-btn
              color="secondary"
              class="mx-2"
              @click="acceptManaged()"
              v-text="translation.general.buttons.save"
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="!showManagae" class="jigsaw-cookies__message">
        <div class="jigsaw-cookies__text">
          <span v-text="translation.all_pages.cookie.cookie_text + ' '" />
          <router-link
            v-if="privacy_url"
            :to="privacy_url"
            v-text="translation.general.labels.learn_more"
          />
        </div>

        <div class="jigsaw-cookies__actions">
          <v-btn
            color="theme4"
            class="mx-2"
            @click="showManagae = !showManagae"
            v-text="translation.general.buttons.manage"
          />
          <v-btn
            color="primary"
            class="mx-2"
            @click="acceptAll()"
            v-text="translation.general.buttons.accept"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gtm from '@/utils/analytics/gtag'
export default {
  name: 'JigsawCookies',
  mixins: [gtm],
  //
  data: () => ({
    privacy_url: false,
    showCookies: false,
    showManagae: false,
    analyticsCode: false,
    jigsawCookies: {
      jigsaw_cookies: false,
      jigsaw_essential_cookie: 1,
      jigsaw_analytics_cookie: 1
    }
  }),
  //
  computed: {
    translation () {
      return this.$store.getters.getTranslation
    }
  },
  //
  created () {
    this.$store.watch(
      (state, getters) => getters.getSiteLinks, (result) => {
        this.privacy_url = result.privacy_url !== '' ? result.privacy_url : false
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getAnalyticsCode, (result) => {
        this.analyticCode = result
      }, {
        immediate: true
      }
    )

    // jigsawCookies
    this.$store.watch(
      (state, getters) => getters.getJigsawCookies, (result) => {
        this.jigsawCookies = result
      }, {
        immediate: true
      }
    )

    this.processCookies()
  },
  //
  methods: {
    processCookies () {
      const jc = {
        jigsaw_cookies: this.$cookies.get('apollo_status') ? this.$cookies.get('apollo_status') : false,
        jigsaw_essential_cookie: this.$cookies.get('apollo_ec') ? this.$cookies.get('apollo_ec') : this.$cookies.get('apollo_status') ? 0 : 1,
        jigsaw_analytics_cookie: this.$cookies.get('apollo_ac') ? this.$cookies.get('apollo_ac') : this.$cookies.get('apollo_status') ? 0 : 1
      }

      if (this.$cookies.get('apollo_ac') && this.$cookies.get('apollo_ac') === '1' && this.analyticCode) {
        // startGaAnalytics(this.analyticCode)
        this.gTagOptIn()
        this.startGtag(this.analyticCode)
      }

      this.$store.commit('setJigsawCookies', jc)

      this.jigsawCookies = jc

      this.showCookies = !this.jigsawCookies.jigsaw_cookies
    },
    //
    acceptAll () {
      this.jigsawCookies.jigsaw_cookies = true
      this.$cookies.set('apollo_status', true, '1y')
      this.$cookies.set('apollo_ec', this.jigsawCookies.jigsaw_essential_cookie, '1y')
      this.$cookies.set('apollo_ac', this.jigsawCookies.jigsaw_analytics_cookie, '1y')
      this.$store.commit('setJigsawCookies', this.jigsawCookies)

      if (this.analyticCode) {
        // this.$gtag.pageview(this.$route)
        // startGaAnalytics(this.analyticCode)
        this.gTagOptIn()
        this.gTagPageView(this, this.$route.path)
      }
    },
    acceptManaged () {
      this.jigsawCookies.jigsaw_cookies = true
      this.$cookies.set('apollo_status', true, '1y')
      this.$cookies.set('apollo_ec', this.jigsawCookies.jigsaw_essential_cookie, '1y')
      this.$cookies.set('apollo_ac', this.jigsawCookies.jigsaw_analytics_cookie, '1y')

      if (this.jigsawCookies.jigsaw_analytics_cookie === 1 && this.analyticCode) {
        // startGaAnalytics(this.analyticCode)
        // gaAnalyticsPageView(this.$route.path)
        this.gTagOptIn()
        this.gTagPageView(this, this.$route.path)
      }
    },
  }
}
</script>
