<template>
  <div class="loader-outer">
    <div
      :style="{
        'fontSize': size,
        'color': color
      }"
    >
      <div class="loader">
        <Icons
          icon-name="bean"
          :height="100"
          :width="100"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icons from '../icons/icons'
export default {
  name: 'SiteLoader',
  components: {
    Icons
  },
  props: {
    size: {
      type: String,
      default: '90px'
    },
    color: {
      type: String,
      default: '#50CBF5'
    }
  }
}
</script>

<style lang="scss">
.loader-outer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  justify-content: center;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
}

.loader {
  display: flex;
  transform-origin: 50% 50%;
  transform: translateZ(0);
  animation: round 1.2s infinite ease;
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
